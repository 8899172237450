<template>
  <div class="box has-text-centered">
    <h1 v-if="!isLoading" class="title is-2 is-marginless">
      {{ hunts.length }}
    </h1>

    <InlineLoader v-else />

    <div class="subtitle is-6 is-marginless">
      {{ $t('dashboard.upcomingHunts') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {

  computed: {
    ...mapGetters({
      user: 'user/data'
    }),

    hunts () {
      let hunts = this.$store.getters['hunt/hunts']

      if (hunts !== null) {
        return hunts.filter(hunt => {
          const now = dayjs()
          return hunt.status === 'planned' && dayjs(hunt.endsAt).isAfter(now)
        })
      }

      return null
    },

    isLoading () {
      return this.hunts === null
    }

  }

}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
