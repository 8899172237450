<template>
  <Wrapper class="page-dashboard">
    <section class="hero is-shadowless">
      <Titlebar class="position-is-absolute">
        <h5 slot="title" class="title is-5 is-marginless">
          {{ fullName }}
        </h5>
      </Titlebar>

      <div class="hero-body">
        <div class="columns">
          <div class="column">
            <TotalHuntAreas />
          </div>

          <div class="column">
            <TotalHunts />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <CampaignCard v-if="hasActiveCampaign" />
            <SubscriptionCard v-else />
          </div>

          <div class="column">
            <LastVisitedHuntAreaCard />
          </div>
        </div>

        <div v-if="invites.length > 0">
          <div class="columns is-multiline">
            <div v-for="(invite, i) in invites" :key="i" class="column is-half">
              <HuntInvitePanel :invite="invite" />
            </div>
          </div>

          <RouterLink v-if="hasMoreInvites" to="/hunts">
            <Button :title="showAllInvitesButtonTitle" />
          </RouterLink>
        </div>

        <div v-if="bannerAds.length === 2" class="columns push-top">
          <div v-for="(ad, i) in bannerAds" :key="i" class="column is-half">
            <Advertisement :advertisement="ad" />
          </div>
        </div>
      </div>
    </section>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

import HuntInvitePanel from '@/views/huntArea/views/hunts/HuntInvitePanel.vue'
import TotalHuntAreas from './TotalHuntAreas.vue'
import TotalHunts from './TotalHunts.vue'
import LastVisitedHuntAreaCard from './LastVisitedHuntAreaCard.vue'

import SubscriptionCard from './SubscriptionCard.vue'
import CampaignCard from './CampaignCard.vue'

import Advertisement from '@/modules/advertisement/components/Advertisement.vue'

export default {
  components: {
    HuntInvitePanel,
    TotalHuntAreas,
    TotalHunts,
    LastVisitedHuntAreaCard,
    SubscriptionCard,
    CampaignCard,
    Advertisement
  },

  data () {
    return {
      visibleInvitesLimit: 4
    }
  },

  computed: {
    ...mapGetters({
      hasSubscription: 'subscription/hasSubscription',
      hasActiveCampaign: 'campaign/isActive'
    }),

    bannerAds () {
      const bannerAds = this.$store.getters['advertisement/getBannerAds']
      return bannerAds.slice(0, 2)
    },

    invites () {
      const invites = this.$store.getters['hunt/invites/invites'] || []
      return invites.slice(0, this.visibleInvitesLimit)
    },

    hasMoreInvites () {
      const invites = this.$store.getters['hunt/invites/invites'] || []
      return invites.length > this.visibleInvitesLimit
    },

    fullName () {
      const user = this.$store.getters['user/data']
      return user !== null ? `${user.firstName} ${user.lastName}` : ''
    },

    showAllInvitesButtonTitle () {
      const invites = this.$store.getters['hunt/invites/invites'] || []
      const diff = invites.length - this.visibleInvitesLimit
      return this.$i18n.tc('hunt.showMoreInvites', diff, { count: diff })
    }
  }
}
</script>

<style lang="scss">
.page-dashboard {
  .hero-body {
    padding: 5.5rem 1.5rem 1.5rem 1.5rem;
  }
}
</style>
