<template>
  <div class="tile is-child box campaign-tile">
    <h1 class="title is-2 has-text-white">{{ $t('campaign.title') }}</h1>
    <h2 class="subtitle is-5 has-text-white">{{ $t('campaign.subtitle') }}</h2>

    <div class="buttons is-centered">
      <button
        :class="{ 'is-loading': isLoading }"
        class="button is-white is-rounded"
        @click="onBuy">
        {{ $t('campaign.getPro') }}
      </button>

      <a
        href="https://wehuntapp.com/pro"
        target="_blank"
        class="button is-text is-rounded">
        {{ $t('campaign.readMore') }}
      </a>
    </div>

    <small class="has-text-white push-top" v-html="$t('campaign.infoText')"></small>
  </div>
</template>

<script>
/*************************************************************************************************/
/* WARNING! We don't have any ongoing campaigns so this component is not used anymore.           */
/* Because of this the code has not been updated to use the new store APIs. This must be updated */
/* before using this component again.                                                            */
/*************************************************************************************************/

import { mapActions } from 'vuex'
import { SUBSCRIPTION_TYPE_STANDARD } from '@/modules/subscription/store/subscriptionStore'

export default {
  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    ...mapActions({
      checkout: 'subscription/checkout',
      purchase: 'subscription/purchase'
    }),

    async onBuy () {
      this.isLoading = true

      try {
        const session = await this.purchase({
          type: SUBSCRIPTION_TYPE_STANDARD,
          returnPath: this.$route.path
        })

        this.checkout(session)
      } catch (error) {
        this.$notification.danger(this.$t('subscription.current.pro.purchaseErrorMessage'))
      }

      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-tile {
  background: var(--noise), linear-gradient(141deg, #fd746c, #ff9068, #ffb88c);
  text-align: center;
  height: 100%;

  .ie & {
    background: linear-gradient(141deg, #ff9068, #ffb88c);
  }

  .title {
    font-family: "Roboto", sans-serif;
    font-size: 3rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-shadow: 2px 3px 3px rgba(218, 108, 70, 0.4);
  }

  .subtitle,
  small {
    text-shadow: 1px 1px 1px rgba(218, 108, 70, 0.15);
  }

  .button {
    font-family: "Roboto", sans-serif;
    font-weight: 500;

    &.is-text {
      color: white;
      text-decoration: none;

      &:hover {
        color: #333;
      }
    }
  }
}
</style>
