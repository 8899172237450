<template>
  <Fragment>
    <div v-if="isLoading" class="box">
      <div>
        <ContentPlaceholders centered>
          <ContentPlaceholdersText :lines="1" />
          <ContentPlaceholdersHeading />
          <ContentPlaceholdersText :lines="1" />
        </ContentPlaceholders>
      </div>
    </div>

    <div v-else-if="!isLoading && hasHuntAreas" class="box">
      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/map`" class="last-visited">
        <Heading class="mb-5" size="3" :text="title" />
        <Paragraph class="has-text-grey-dark" :text="subtitle" />

        <div class="buttons are-small">
          <RouterLink :to="`/area/${huntArea.id}/dashboard`" class="button">
            <Icon name="icon-map" />
            <span>{{ $t('area.nav.dashboard') }}</span>
          </RouterLink>

          <RouterLink :to="`/calendar?huntAreaId=${huntArea.id}`" class="button">
            <Icon name="icon-calendar" />
            <span>{{ $t('area.nav.calendar') }}</span>
          </RouterLink>
        </div>
      </RouterLink>
    </div>

    <div v-else-if="!isLoading && !hasHuntAreas" class="box">
      <EmptyState icon="icon-map" :tagline="$t('areas.noAreasFound')">
        <Button
          type="primary"
          :title="$t('huntArea.createHuntingGround')"
          @click="toggleHuntAreaDialog">
        </Button>
      </EmptyState>

      <NewHuntAreaDialog
        v-if="isHuntAreaDialogActive"
        @close="toggleHuntAreaDialog">
      </NewHuntAreaDialog>
    </div>
  </Fragment>
</template>

<script>
import NewHuntAreaDialog from '@/components/dialogs/NewHuntAreaDialog.vue'

export default {
  components: {
    NewHuntAreaDialog
  },

  data () {
    return {
      huntArea: null,
      isRandomHuntArea: false,

      noHuntAreasFound: false,
      isHuntAreaDialogActive: false
    }
  },

  computed: {
    huntAreas () {
      return this.$store.getters['huntarea/areas']
    },

    hasHuntAreas () {
      return this.huntAreas && this.huntAreas.length >= 1
    },

    isLoading () {
      return !this.huntAreas
    },

    title () {
      return this.$utils.capitalize(this.huntArea.name)
    },

    subtitle () {
      return this.isRandomHuntArea
        ? this.$t('dashboard.lastVisited.randomHuntArea')
        : this.$t('dashboard.lastVisited.title')
    }
  },

  watch: {
    huntAreas () {
      this.huntArea = this.getHuntArea()
    }
  },

  mounted () {
    this.huntArea = this.getHuntArea()
  },

  destroyed () {
    this.huntArea = null
  },

  methods: {
    getHuntArea () {
      if (!this.hasHuntAreas) return

      const lastVisitedHuntAreaId = window.localStorage.getItem('lastVisitedArea')

      if (lastVisitedHuntAreaId) {
        const huntArea = this.huntAreas.filter(huntArea => huntArea.id === lastVisitedHuntAreaId)[0]

        if (huntArea) {
          return huntArea
        } else {
          return this.getRandomHuntArea()
        }
      } else {
        return this.getRandomHuntArea()
      }
    },

    getRandomHuntArea () {
      if (!this.hasHuntAreas) return

      this.isRandomHuntArea = true

      const random = Math.floor(Math.random() * this.huntAreas.length)
      const huntArea = this.huntAreas[random]

      return huntArea
    },

    toggleHuntAreaDialog () {
      this.isHuntAreaDialogActive = !this.isHuntAreaDialogActive
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 2.5rem 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .last-visited {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.loader-placeholder {
    display: block;
  }

  .vue-content-placeholders {
    width: 100%;
  }
}
</style>
