<template>
  <div class="box has-text-centered">
    <h1 v-if="!isLoading" class="title is-2 is-marginless">
      {{ huntAreas.length }}
    </h1>

    <InlineLoader v-else />

    <div class="subtitle is-6 is-marginless">
      {{ $t('dashboard.huntAreas') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters({
      huntAreas: 'huntarea/areas'
    }),

    isLoading () {
      return this.huntAreas === null
    }
  }

}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
