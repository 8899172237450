<template>
  <Fragment>
    <div v-if="isLoading" class="tile is-child box">
      <div>
        <ContentPlaceholders centered>
          <ContentPlaceholdersText :lines="1" />
          <ContentPlaceholdersHeading />
          <ContentPlaceholdersText :lines="1" />
        </ContentPlaceholders>
      </div>
    </div>

    <div v-if="!isLoading && !hasSubscription" class="tile is-child box get-pro-tile">
      <div class="box">
        <p class="title has-text-primary">
          {{ $t('dashboard.subscription.upgradeTitle') }}
        </p>

        <p v-html="$t('dashboard.subscription.upgradeSubtitle')"></p>
        <p v-if="isTrialSubscription" v-html="$t('dashboard.subscription.trialMessage', { expiresAt: expireDate })"></p>
        <p>
          <RouterLink to="/profile/subscription">
            {{ $t('dashboard.subscription.upgrade') }}
          </RouterLink>
        </p>
      </div>
    </div>

    <div v-if="!isLoading && hasSubscription" class="box has-pro-tile">
      <Heading class="mb-5" size="3" :text="subscriptionTypeName" />

      <div v-if="hasGps">
        <p class="is-marginless">
          <span v-if="hasUpcomingGpsPayment">{{ $t('dashboard.subscription.nextPaymentAt') }}</span>
          <span v-else>{{ $t('dashboard.subscription.subscriptionExpires') }}</span>
        </p>

        <p class="title is-5 is-marginless">
          <span v-if="hasUpcomingGpsPayment">{{ nextGpsPaymentDate }}</span>
          <span v-else>{{ gpsExpireDate }}</span>
        </p>
      </div>

      <div v-else>
        <p class="is-marginless">
          <span v-if="autoRenew">{{ $t('dashboard.subscription.nextPaymentAt') }}</span>
          <span v-else>{{ $t('dashboard.subscription.subscriptionExpires') }}</span>
        </p>

        <p class="title is-5 is-marginless">{{ expireDate }}</p>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { SUBSCRIPTION_TYPE_TRIAL, SUBSCRIPTION_TYPE_GPS, SUBSCRIPTION_TYPE_GPS_MAX_PREFIX } from '@/modules/subscription/store/subscriptionStore'

export default {
  computed: {
    ...mapGetters({
      subscriptionType: 'subscription/type',
      hasSubscription: 'subscription/hasSubscription',
      isLoading: 'subscription/isLoading',
      devices: 'subscription/devices',
      autoRenew: 'subscription/autoRenew'
    }),

    isTrialSubscription () {
      return this.subscriptionType === SUBSCRIPTION_TYPE_TRIAL
    },

    subscriptionTypeName () {
      if (this.subscriptionType.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)) {
        const namePrefix = this.$t('subscription.gps_max.name')

        return namePrefix + ' ' + this.subscriptionType.substring(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX.length)
      }

      return this.$t(`subscription.${this.subscriptionType}.name`)
    },

    hasGps () {
      return this.subscriptionType === SUBSCRIPTION_TYPE_GPS
    },

    expireDate () {
      const expireDate = this.$store.getters['subscription/expires']
      return this.$dayjs(expireDate).format('YYYY-MM-DD')
    },

    nextGpsPaymentDate () {
      const paymentDate = this.devicesWithUpcomingPayments[0].expires
      return this.formatDate(paymentDate)
    },

    devicesWithUpcomingPayments () {
      const devices = this.devices || []
      const devicesWithUpcomingPayments = devices.filter(device => {
        return device.autoRenew && device.expires > new Date()
      })

      devicesWithUpcomingPayments.sort((a, b) => a.expires.getTime() - b.expires.getTime())
      return devicesWithUpcomingPayments
    },

    hasUpcomingGpsPayment () {
      return this.devicesWithUpcomingPayments.length > 0
    },

    gpsExpireDate () {
      const devices = this.devices.filter(device => device.autoRenew === false)
      let expireDate = devices[0].expires

      for (const device of devices) {
        if (device.expires > expireDate) {
          expireDate = device.expires
        }
      }

      return this.formatDate(expireDate)
    }
  },

  methods: {
    formatDate (date) {
      return this.$dayjs(date).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >div {
    width: 100%;
  }
}

.get-pro-tile {
  padding: 0.75rem;
  height: 100%;
  color: white;
  text-align: center;
  background: var(--noise),
    linear-gradient(141deg, #f24e14 0%, #eb914e 71%, #f2b761 100%);

  .ie & {
    background: linear-gradient(141deg, #eb914e, #f2b761);
  }

  .box {
    height: 100%;
    width: 100%;
  }
}

.has-pro-tile {
  padding: 2.5rem 1.5rem;
  text-align: center;
}

.vue-content-placeholders {
  width: 100%;
}
</style>
